@import "./variables.less";

.navigation {
  min-height: 100vh;
  .ant-layout-sider {
    height: 100vh;
    max-height: 100vh;
    box-shadow: 0 0.125rem 0.25rem #a5a3ae4d;
    user-select: none;
    padding: 0 .9375rem;
    &.ant-layout-sider-collapsed {
      padding: 0;
    }
    .logo_box {
      height: 5.125rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon_box {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        .logo {
          width: 130.8px;
          max-width: 130.8px;
          min-width: 130.8px;
        }
      }
      .nav_close {
        cursor: pointer;
      }
      &.collapsed {
        max-width: 40px;
        width: fit-content;
        margin: 0 auto;
        padding: 0;
        // justify-content: center;
        .icon_box {
          min-width: 1.25rem;
          max-width: 1.25rem;
        }
        .nav_close {
          display: none;
        }
      }
      .anticon {
        display: none;
        font-size: 1rem;
        color: rgba(255, 255, 255, .38);
      }
    }
  }
  .ant-layout {
    background: unset;
  }
  .nav_power {
    font-size: 1rem;
    margin-top: .5rem;
    margin-left: 1rem;
    color: rgba(255, 255, 255, .38);
  }
}

@media (max-width: 1200px)  {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: all .5s;
    &[open]{
      width: 100vw;
      background: #4b465c7f;
    }
  }
}