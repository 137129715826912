@import "./variables.less";

.cp_chat {
  background-color: #FFFFFF;
  height: 100%;
  max-height: 100vh;
  // padding-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  .cp_chat_main {
    // padding: 1.5rem;
    flex: 1;
    overflow: auto;
    background-color: #F7F7F7;
    .chat_item {
      .chat_me_bg {
        padding: 1.5rem 0;
        background-color: #F7F7F7;
      }
      .chat_me {
        display: flex;
        // margin-bottom: 1.25rem;
        max-width: 55rem;
        margin: 0 auto;
        .chat_me_icon {
          position: relative;
          width: 2.25rem;
          min-width: 2.25rem;
          height: 2.25rem;
          font-size: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #EBF4FF;
          color: @primary-color;
          user-select: none;
          cursor: pointer;
          margin-right: .75rem;
        }
        .chat_me_msg {
          padding: .375rem 0;
          flex: 1;
          width: 78.33%;
          max-width: 90rem;
          // background-color: @primary-color;
          // color: #FFFFFF;
          font-size: .9375rem;
          // border-radius: 0 .5rem .5rem;
          word-wrap: break-word;
          white-space: pre-line;
        }
      }
      .chat_bot_bg {
        padding: 1.5rem 0;
        background-color: #FFFFFF;
      }
      .chat_bot {
        display: flex;
        // margin-bottom: 1.25rem;
        max-width: 55rem;
        margin: 0 auto;
        .chat_bot_icon {
          width: 2.25rem;
          min-width: 2.25rem;
          height: 2.25rem;
          border-radius: 50%;
          user-select: none;
          cursor: pointer;
          overflow: hidden;
          margin-right: .75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          &>img {
            max-width: 2.25rem;
            max-height: 2.25rem;
          }
        }
        .chat_box_bubble {
          flex: 1;
          width: 78.33%;
          max-width: 90rem;
          font-size: .9375rem;
          // border-radius: 0 .5rem .5rem;
          // border: 1px solid #dfdfdf;
          word-wrap: break-word;
          // background-color: #fefefe;
          // overflow: hidden;
          // box-shadow: 0 0.25rem 0.25rem #a5a3ae4d;
        }
        .chat_bot_msg {
          padding: .375rem 0;
          color: #4B465C;
          position: relative;
          .chat_bot_helper {
            width: fit-content;
            font-size: .875rem;
            line-height: .875rem;
            padding: .25rem .375rem;
            display: flex;
            align-items: center;
            border-radius: 0.375rem;
            border: 1px solid #D8D8D8;
            user-select: none;
            cursor: pointer;
            margin-bottom: .25rem;
            .chat_emoji {
              // width: 1.25rem;
              scale: 1.2;
              margin-right: .25rem;
            }
          }
          &.pre_line{
            white-space: pre-line;
          }
          &.generating {
            .markdown >:last-child {
              position: relative;
              // display: inline-block;
              // background-color: red;
              &::after {
                content: '▮';
                display: inline-block;
                animation: blink 1s infinite;
              }
            }
          }
          .chat_bot_opt {
            position: sticky;
            width: fit-content;
            top: 0;
            left: 100%;
            line-height: 1rem;
            font-size: 1rem;
            margin-bottom: -1rem;
            text-align: right;
            .anticon {
              cursor: pointer;
              color: #999999;
              padding: .25rem;
              background-color: #fefefe;
              border-radius: .25rem;
              &:hover {
                background-color: #eee;
                color: #666666;
              }
            }
            .anticon + .anticon {
              margin-left: .25rem;
            }
          }
          p > code {
            color: 111827;
            font-weight: 600;
          }
          @keyframes blink {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }

          .chat_bot_questions {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin-top: 1rem;
            font-size: .875rem;
            line-height: 1.5rem;
            .chat_bot_ques_title {
              margin-right: .3125rem;
              font-weight: 600;
              margin-bottom: .3125rem;
            }
            .chat_bot_ques_item {
              width: fit-content;
              max-width: 100%;
              font-style: italic;
              font-weight: 500;
              border-radius: 4px;
              padding: 0 .5rem;
              background: @primary-color;
              color: #fff;
              text-decoration: none;
              cursor: pointer;
              margin-right: .3125rem;
              margin-bottom: .3125rem;
              white-space: nowrap;
              &>div {
                overflow: hidden;//文本超出隐藏
                text-overflow: ellipsis;//文本超出省略号替代
              }
            }
          }
        }
        .chat_bot_info {
          display: flex;
          flex-direction: column;
          padding: .8rem 1rem;
          background-color: #f7f7f7;
          .chat_bot_info_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .get_file {
              font-size: 1rem;
              cursor: pointer;
            }
          }
          .chat_bot_info_body {
            margin-top: .3125rem;
            .chat_bot_info_title {
              font-size: .75rem;
              color: #999999;
            }
            .chat_bot_info_item {
              color: @primary-color;
              font-size: .9375rem;
              line-height: .9375rem;
              margin-bottom: .4375rem;
              width: fit-content;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .cp_chat_ask {
    padding: .75rem 0 3.125rem;
    background-color: #F7F7F7;
    position: sticky;
    bottom: 0;
    z-index: 10;
    .cp_chat_ask_main {
      max-width: 55rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding: 1.125rem;
      border-radius: .625rem;
      background-color: #FFFFFF;
      .cp_chat_ask_top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom:  .25rem;
        .cp_prompt_top {
          display: flex;
          .ant-tag {
            margin-bottom: .5rem;
          }
        }
        .cp_prompt_bottom {
          display: flex;
          .cp_prompt_s_item {
            width: 7rem;
            display: flex;
            flex-direction: column;
            &+.cp_prompt_s_item {
              margin-left: 1.25rem;
            }
            .cp_prompt_s_title {
              font-weight: 500;
            }
            .ant-select {
              border-radius: .25rem;
              background: #ECECF1;
            }
          }
        }
      }
      .cp_chat_ask_bottom {
        display: flex;
        // align-items: center;
        padding: .25rem 5.25rem 0 0;
        position: relative;
        textarea {
          border: none;
          box-shadow: unset;
        }
        .cp_chat_ask_flot {
          position: absolute;
          right: .75rem;
          bottom: 0rem;
          display: flex;
          align-items: center;
          .cp_chat_ask_counter {
            font-size: .875rem;
            color: #999999;
            span {
              color: #333333;
            }
          }
          .cp_chat_ask_enter {
            display: flex;
            margin-left: .625rem;
            cursor: pointer;
            img {
              max-width: 20px;
            }
          }
        }
      }
    }
  }
  .cp_chat_power {
    width: 100%;
    height: 2rem;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8125rem;
    font-weight: 700;
    position: absolute;
    bottom: 0;
  }
}

.cp_progress {
  width: 100%;
  height: .3125rem;
  position: relative;
  overflow: hidden;
  border-radius: .3125rem;
  .cp_pgs_bg {
    background-color: rgba(76, 175, 80, 0.12);
    height: inherit;
  }
  .cp_pgs_bar {
    height: inherit;
    .cp_pgs_ani {
      animation-duration: 2.2s;
      animation-iteration-count: infinite;
      position: absolute;
      height: inherit;
      width: auto;
      bottom: 0;
      left: 0;
      right: auto;
      top: 0;
      will-change: left,right;
      border-radius: .3125rem;
    }
    .cp_pgs_ani.long {
      animation-name: ani_long;
      background-color: rgba(76, 175, 80, 1);
    }
    .cp_pgs_ani.short {
      animation-name: ani_short;
      background-color: rgba(76, 175, 80, 1);
    }
  }
  @keyframes ani_long {
    0% {
      left: -90%;
      right: 100%;
    }
    60% {
        left: -90%;
        right: 100%;
    }
    100% {
        left: 100%;
        right: -35%;
    }
  }
  @keyframes ani_short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
  }
}

.char_form {
  p {
    margin: .5rem 0;
  }
}

@media ( max-width: 768px ) {
  .cp_chat {
    .cp_chat_main {
      // margin-bottom: 1rem;
      .chat_item {
        .chat_me_bg {
          padding: .5rem 0;
        }
        .chat_me {
          // justify-content: flex-end;
          .chat_me_icon {
            // display: none;
            margin: .25rem 0 0 1rem;
            width: 1.875rem;
            min-width: 1.875rem;
            max-width: 1.875rem;
            height: 1.875rem;
          }
          .chat_me_msg {
            width: fit-content;
            flex: unset;
            padding: .5rem 1rem;
            border-radius: .5rem .5rem 0;
          }
        }
        .chat_bot_bg {
          padding: .5rem 0;
        }
        .chat_bot {
          // margin-right: 1rem;
          .chat_bot_icon {
            // display: none;
            margin: .375rem 0 0 1rem;
            width: 1.875rem;
            min-width: 1.875rem;
            max-width: 1.875rem;
            height: 1.875rem;
            > img {
              max-width: 1.875rem;
              max-height: 1.875rem;
            }
          }
          .chat_box_bubble {
            border-radius: .5rem .5rem .5rem 0;
          }
          .chat_bot_msg {
            padding: .5rem 1rem;
  
            .chat_bot_questions {

              .chat_bot_ques_title {
                // display: none;
              }
              .chat_bot_ques_item {
                box-sizing: content-box;
                width: unset;
                font-size: 1rem;
                line-height: 1.125rem;
                padding: .75rem .875rem;
                margin-bottom: .5rem;
                text-align: center;
                border-radius: .5rem;
                white-space: inherit;
                &>div {
                  max-height: 2.25rem;
                  word-break: break-word;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                }
              }
            }
          }
          .chat_bot_info {
            padding: .5rem 1rem;
            .chat_bot_info_header {
              .get_file {
                cursor: pointer;
              }
            }
            .chat_bot_info_body {
              margin-top: .3125rem;
              .chat_bot_info_title {
  
              }
            }
          }
        }
      }
    }
    .cp_chat_ask {
      padding: .5rem;
      .cp_chat_ask_main {
        padding: .8rem;
        .cp_chat_ask_bottom {
          margin-bottom: 0rem;
          .anticon {
          }
          textarea {
          }
        }
      }
    }
    .cp_chat_power {
     
    }
  }

  .char_form {
    p {
      margin: .3125rem 0;
    }
    .ant-form-item {
      margin-bottom: .3125rem;
      &:last-child {
        margin-top: 1rem;
      }
    }
    // .ant-form-item-control-input-content {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   .ant-btn {
    //     margin-right: .5rem;
    //   }
    // }
  }
}




// markdown样式
// 代码块
.markdown .chat_code_box {
  border-radius: .375rem;
  overflow: hidden;
}
.markdown .chat_code_box > div {
  margin: 0 !important;
}
.markdown .chat_code_box .chat_code_header {
  height: 2rem;
  padding: 0 .5rem 0 1rem;
  background-color: #343541;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #D9D9E3;
}
.markdown .chat_code_box .chat_code_header button {
  font-size: 12px;
  color: #D9D9E3;
}
.markdown > p {
  margin: 1rem 0;

}
// 第一个p
.markdown > p:first-child {
  margin-top: 0;
}
// 最后一个p
.markdown > p:last-child {
  margin-bottom: 0;
}
.markdown p {
  line-height: 1.75;
}
.markdown ol {
  counter-reset: item
}

.markdown ol li {
  list-style-type: decimal;
}
.markdown ul li {
  display: block;
  margin: 0;
  position: relative;
}
.markdown ul li:before {
  content: "•";
  font-size: .875rem;
  line-height: 1.25rem;
  margin-left: -1rem;
  position: absolute
}
.markdown sup {
  background-color: @primary-color;
  color: #FFFFFF;
  padding: 0 .25rem;
  border-radius: .25rem;
  cursor: pointer;
  vertical-align: top;
}
.markdown {
  max-width: none
}
.markdown h1,.markdown h2 {
  font-weight: 600
}
.markdown h2 {
  margin-bottom: 1rem;
  margin-top: 2rem
}
.markdown h3 {
  font-weight: 600
}
.markdown h3,.markdown h4 {
  margin-bottom: .5rem;
  margin-top: 1rem
}
.markdown h4 {
  font-weight: 400
}
.markdown h5 {
  font-weight: 600
}
.markdown blockquote {
  --tw-border-opacity: 1;
  border-color: rgba(142,142,160,var(--tw-border-opacity));
  border-left-width: 2px;
  line-height: 1rem;
  padding-left: 1rem
}
.markdown ol,.markdown ul {
  display: flex;
  flex-direction: column;
  padding-left: 1rem
}
.markdown ol li,.markdown ol li>p,.markdown ol ol,.markdown ol ul,.markdown ul li,.markdown ul li>p,.markdown ul ol,.markdown ul ul {
  margin: 0
}
.markdown table {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-collapse: separate;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%
}
.markdown th {
  background-color: rgba(236,236,241,.2);
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
  padding: .25rem .75rem
}
.markdown th:first-child {
  border-top-left-radius: .375rem
}
.markdown th:last-child {
  border-right-width: 1px;
  border-top-right-radius: .375rem
}
.markdown td {
  border-bottom-width: 1px;
  border-left-width: 1px;
  padding: .25rem .75rem
}
.markdown td:last-child {
  border-right-width: 1px
}
.markdown tbody tr:last-child td:first-child {
  border-bottom-left-radius: .375rem
}
.markdown tbody tr:last-child td:last-child {
  border-bottom-right-radius: .375rem
}
.markdown a {
  text-decoration-line: underline;
  text-underline-offset: 2px
}